import { GeoLevelSavingsData, SavingsData, ExactSavingsData } from "./types";

export function isGeoLevelSavingsData(
	data: SavingsData
): data is GeoLevelSavingsData {
	return (data as GeoLevelSavingsData).savingsType !== undefined;
}

export function isExactSavingsData(
	data: SavingsData
): data is ExactSavingsData {
	return (data as ExactSavingsData)?.apn !== undefined;
}
